import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
export interface IMFRMCheckoutStepperState {
    customerInfoSaveClicked: boolean;
    currentStep?: number;
}

/**
 * SampleState - action input
 */
export class CheckoutStepperInfoInput implements IActionInput {
    public getCacheKey = () => `CheckoutStepperInfoInput`;
    public getCacheObjectType = () => 'CheckoutStepperInfoInput';
    public dataCacheType = (): CacheType => 'request';
}

/**
 * SampleState - action
 */
export async function updateCheckoutZipCodeValidityAction(input: CheckoutStepperInfoInput, ctx: IActionContext): Promise<IMFRMCheckoutStepperState> {
    return { customerInfoSaveClicked: false };
}

/**
 * SampleState - create new input for create action
 */
const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    return new CheckoutStepperInfoInput();
};

/**
 * SampleState - create action
 */
export default createObservableDataAction<IMFRMCheckoutStepperState>({
    id: 'CheckoutStepperInfoInput',
    action: <IAction<IMFRMCheckoutStepperState>>updateCheckoutZipCodeValidityAction,
    input: createInput
});