import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { IMFIATPInventoryDynamicEntity } from '../actions/CoreProductDataServiceEntities.g';

export interface IATPSlotInfo {
    selectedATPSlotInfo: IMFIATPInventoryDynamicEntity | undefined;
    selectedATPSlotDate: string | undefined;
}

/**
 * SampleState - action input
 */
export class SelectedATPSlotInfo implements IActionInput {
    public getCacheKey = () => `SelectedATPSlotInfo`;
    public getCacheObjectType = () => 'SelectedATPSlotInfo';
    public dataCacheType = (): CacheType => 'none';
}
/**
 * SampleState - action
 */
export async function getSelectedATPSlotInfo(input: SelectedATPSlotInfo, ctx: IActionContext): Promise<IATPSlotInfo> {
    return { selectedATPSlotInfo: undefined, selectedATPSlotDate: undefined };
}
/**
 * SampleState - create new input for create action
 */
const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    return new SelectedATPSlotInfo();
};
/**
 * SampleState - create action
 */
export default createObservableDataAction<IATPSlotInfo>({
    id: 'SelectedATPSlotInfo',
    action: <IAction<IATPSlotInfo>>getSelectedATPSlotInfo,
    input: createInput
});