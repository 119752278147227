import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    IAny,
    ICreateActionContext,
    IGeneric
} from '@msdyn365-commerce/core';
import { INavigationMenuState } from './navigation-menu-state';

/**
 * NavigationMenuState - action input
 */
export class NavMenuStateInput implements IActionInput {
    public getCacheKey = () => `NavMenuState`;
    public getCacheObjectType = () => 'NavMenuState';
    public dataCacheType = (): CacheType => 'application';
}

/**
 * NavMenuStateInput - action
 */
export async function navMenuStateAction(input: NavMenuStateInput, ctx: IActionContext): Promise<INavigationMenuState> {
    return { isMenuHasParent: false, isMobileMenuCollapsed: true, selectedNavMenu: '', menuItemData: [] };
}

/**
 * NavMenuStateInput - create new input for create action
 */
const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    return new NavMenuStateInput();
};

/**
 * NavMenuStateInput - create action
 */
export default createObservableDataAction<INavigationMenuState>({
    id: 'NavMenuState',
    action: <IAction<INavigationMenuState>>navMenuStateAction,
    input: createInput
});
