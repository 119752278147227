import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';

export interface IContinueToPaymentState {
    shouldGoToPayment: boolean;
    triggerGetCardPayment: string;
}

/**
 * SampleState - action input
 */
export class ContinueToPaymentInput implements IActionInput {
    public getCacheKey = () => `ContinueToPaymentInput`;
    public getCacheObjectType = () => 'ContinueToPaymentInput';
    public dataCacheType = (): CacheType => 'none';
}
/**
 * SampleState - action
 */
export async function updateContinueToPaymentStateAction(input: ContinueToPaymentInput, ctx: IActionContext): Promise<IContinueToPaymentState> {
    return {shouldGoToPayment: true, triggerGetCardPayment: 'stopTrigger' };
}
/**
 * SampleState - create new input for create action
 */
const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    return new ContinueToPaymentInput();
};
/**
 * SampleState - create action
 */
export default createObservableDataAction<IContinueToPaymentState>({
    id: 'ContinueToPaymentInput',
    action: <IAction<IContinueToPaymentState>>updateContinueToPaymentStateAction,
    input: createInput
});