import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
export interface ICheckoutZipCodeInfoState {
    isValidZipCode: boolean;
    updatedZipCode: string;
    isOOMZipCode: boolean;
}

/**
 * SampleState - action input
 */
export class CheckoutZipCodeInfoInput implements IActionInput {
    public getCacheKey = () => `CheckoutZipCodeInfoInput`;
    public getCacheObjectType = () => 'CheckoutZipCodeInfoInput';
    public dataCacheType = (): CacheType => 'request';
}

/**
 * SampleState - action
 */
export async function updateCheckoutZipCodeValidityAction(input: CheckoutZipCodeInfoInput, ctx: IActionContext): Promise<ICheckoutZipCodeInfoState> {
    return { isValidZipCode: false, updatedZipCode: '', isOOMZipCode: false };
}

/**
 * SampleState - create new input for create action
 */
const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    return new CheckoutZipCodeInfoInput();
};

/**
 * SampleState - create action
 */
export default createObservableDataAction<ICheckoutZipCodeInfoState>({
    id: 'CheckoutZipCodeInfo',
    action: <IAction<ICheckoutZipCodeInfoState>>updateCheckoutZipCodeValidityAction,
    input: createInput
});